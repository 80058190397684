import { initializeApp } from "firebase/app";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { getAuth, onAuthStateChanged, User, browserLocalPersistence, setPersistence } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { initializeFirestore } from "firebase/firestore";
import config from "config";
import { FirebaseApp } from 'firebase/app';

// Single Responsibility: Firebase initialization
const initializeFirebase = () => {
  const firebase = initializeApp({
    apiKey: config.API_KEY,
    authDomain: config.AUTH_DOMAIN,
    databaseURL: config.DATABASE_URL,
    projectId: config.PROJECT_ID,
    storageBucket: config.STORAGE_BUCKET,
    messagingSenderId: config.MESSAGING_SENDER_ID,
    appId: config.APP_ID,
    measurementId: config.MEASUREMENT_ID,
  });

  // Initialize AppCheck based on environment
  initializeAppCheckWithConfig(firebase);

  return firebase;
};

// Single Responsibility: AppCheck initialization
const initializeAppCheckWithConfig = (app: FirebaseApp) => {
  if (config.APPCHECK_E2E_OVERIDE) {
    (window as unknown as { FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string })
      .FIREBASE_APPCHECK_DEBUG_TOKEN = config.APPCHECK_E2E_OVERIDE;
  } else if (window.location.hostname === "localhost") {
    (window as unknown as { FIREBASE_APPCHECK_DEBUG_TOKEN: boolean }).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }

  return initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(config.RECAPTCHA_KEY),
    isTokenAutoRefreshEnabled: true,
  });
};

// Initialize Firebase instance
const firebase = initializeFirebase();

// Initialize services with dependency injection pattern
export const db = initializeFirestore(firebase, {
  experimentalAutoDetectLongPolling: true,
  ignoreUndefinedProperties: true,
});

export const analytics = getAnalytics(firebase);
export const storage = getStorage(firebase);
export const functions = getFunctions(firebase, config.BACKEND_REGION);
export const auth = getAuth(firebase);

// Add auth state persistence and monitoring
setPersistence(auth, browserLocalPersistence);

// Add token refresh mechanism
const setupTokenRefresh = (user: User) => {
  let refreshTimeout: NodeJS.Timeout;
  let checkInterval: NodeJS.Timeout;

  const scheduleRefresh = async () => {
    try {
      const token = await user.getIdTokenResult();
      const expirationTime = new Date(token.expirationTime).getTime();
      const now = Date.now();
      const timeUntilRefresh = expirationTime - now - config.AUTH_TOKEN_REFRESH_BUFFER;

      refreshTimeout = setTimeout(async () => {
        await user.getIdToken(true);
        scheduleRefresh(); // Schedule next refresh
      }, Math.max(0, timeUntilRefresh));
    } catch (error) {
      console.error('Token refresh failed:', error);
    }
  };

  // Add periodic token check
  const startPeriodicCheck = () => {
    checkInterval = setInterval(async () => {
      try {
        await user.getIdToken(true);
        console.debug('Periodic token refresh completed');
      } catch (error) {
        console.error('Periodic token refresh failed:', error);
      }
    }, config.AUTH_TOKEN_CHECK_INTERVAL);
  };

  scheduleRefresh();
  startPeriodicCheck();
  
  return () => {
    clearTimeout(refreshTimeout);
    clearInterval(checkInterval);
  };
};

// Update auth state observer
const authStateObserver = (user: User | null) => {
  if (user) {
    setupTokenRefresh(user);
  }
};

// Set up auth state listener
onAuthStateChanged(auth, authStateObserver);

export default firebase;
