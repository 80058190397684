import { FC, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import pattern from "assets/grunge/pattern.svg";
import { useAppSelector } from "store";
import { projectTitleSelector } from "store/selectors";
import { selectProjectId } from "store/project";
import SettingsMenuContainer from "Components/Menu/SettingsMenuContainer";
import { useOrganisation } from "hooks";
import Breadcrumb from "Pages/sites/Breadcrumb";
import { selectRole } from "store/auth";
import { UserRole } from "@ehabitation/ts-utils/browser";
import logo_small from "assets/images/logo_square.png";
import UnitDropdown from "Components/UnitDropdown/UnitDropdown";

const dotMatrixBgStyle = {
  backgroundImage: `url(${pattern})`,
  backgroundRepeat: "repeat",
  backgroundPosition: "center",
};

const Header: FC = () => {
  const location = useLocation();
  const { pathname } = location;

  const projectTitle = useAppSelector(projectTitleSelector);
  const projectId = useAppSelector(selectProjectId);
  const role = useAppSelector(selectRole);
  const [showBetaDialog, setShowBetaDialog] = useState(false);

  const showBreadcrumbs =
    role !== UserRole.superAdmin && pathname.includes("sites");
  const isPlanner = pathname.endsWith("plan");

  const organisation = useOrganisation();

  const handleBetaClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowBetaDialog(true);
  };

  const handleDialogConfirm = () => {
    setShowBetaDialog(false);
    window.open("https://beta.ehab.co/", "_blank", "noopener,noreferrer");
  };

  return (
    <header
      className="flex shrink-0 items-center w-full bg-white h-22 sm:h-22 z-50"
      style={dotMatrixBgStyle}
    >
      <div className="flex h-full mr-auto">
        {/* {isFreeUser ? null : <h2>{organisation?.name}</h2>} */}
        {showBreadcrumbs && organisation && (
          <div className="relative">
            <div className="top-0 left-0 min-w-[650px] w-full z-9999">
              <Breadcrumb />
            </div>
          </div>
        )}
      </div>

      {!showBreadcrumbs && (
        <div className="flex items-center w-full ml-10 mr-8 mt-4">
          <div className="flex-col justify-center items-start" data-testid="logo-link">
            {/* Logo for small screens */}
            <a href="/">
              <img
                src={logo_small}
                alt="EHAB Small Logo"
                className="block lg:hidden h-20 sm:h-20"
              />
            </a>
            {/* Logo for large screens */}
            <a href="/">
              <img
                src={logo_small}
                alt="EHAB Logo"
                className="hidden lg:block h-12"
              />
            </a>
          </div>
          {isPlanner ? (
            <h4
              data-testid="project-title"
              className="ml-10 bg-white/[.06] capitalize p-2"
            >
              {projectTitle}
            </h4>
          ) : (
            <Link to={`/projects/${projectId}`} className="ml-10 text-gray-700">
              <h4
                data-testid="project-title"
                className="bg-white/[.06] capitalize p-2"
              >
                {projectTitle}
              </h4>
            </Link>
          )}
        </div>
      )}

      <p className="sm:hidden px-6 text-gray-500 italic">Mobile View</p>

      <div className="flex items-center mr-10 mt-2 space-x-4">
        <div className="flex items-center">
          <span className="mr-2 text-gray-700">Try our new platform:</span>
          <a 
            href="#"
            onClick={handleBetaClick}
            className="px-3 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors"
          >
            EHAB Beta
          </a>
        </div>
        <UnitDropdown />
        <SettingsMenuContainer />
      </div>

      {showBetaDialog && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md mx-auto">
            <h3 className="text-xl font-semibold mb-4">Welcome to EHAB Beta!</h3>
            <p className="mb-3">
              You&apos;re about to access our beta platform. This is part of our transition phase to a new and improved EHAB experience.
            </p>
            <p className="mb-3">
              You can log in using your existing credentials.
            </p>
            <p className="mb-4">
              We welcome and greatly appreciate your feedback during this period as it helps us improve the platform for everyone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowBetaDialog(false)}
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100"
              >
                Cancel
              </button>
              <button
                onClick={handleDialogConfirm}
                className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
              >
                Continue to Beta
              </button>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
